import React from 'react';

export default class Work extends React.Component {
  render() {    
    return (
        <body>
            <section className='team-section'>
              <div class="wrapper">
                <div className='page-header'>
                    <h1 className='underline-heading'>Communities We've Built With</h1>
                </div>
                <div className='work-1-img'>
                    <img className='team-left-img' src={require('../../images/mxtter.jpg')}></img>
                </div>
                <div className='work-2-img'>
                    <img className='team-left-img' src={require('../../images/ttpfp.png')}></img>
                </div>
                <div className='work-3-img'>
                    <img className='team-left-img' src={require('../../images/sgpfp.jpg')}></img>
                </div>
                <div className='work-4-img'>
                    <img className='team-left-img' src={require('../../images/dfpfp.jpg')}></img>
                </div>
              </div>
              
            </section>
        </body>
      
    );
  }
}