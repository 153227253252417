import React from 'react';
import { Link } from 'react-router-dom'

export default class Footer extends React.Component {
  render() {    
    return (
      <nav className="Footer">
        <div className="footer-container">
          <div>
            <p>ALL RIGHTS RESERVED</p>
          </div>  
          <div>
            <p>2024</p>
          </div>
        </div>
      </nav>
    );
  }
}