import React from 'react';
import { Link } from 'react-router-dom'


export default class Nav extends React.Component {
  navFunction() {
    var x = document.getElementById("myTopnav");
    if (x.className === "topnav") {
      x.className += " responsive";
    } else {
      x.className = "topnav";
    }
  }

  render() {    
    return (
      <div className="topnav" id="myTopnav">
          <a href="Home">Home</a>
          <a href="Team">Team</a>
          <a href="Bots">Bots</a>
          <a href="Pricing">Pricing</a>
          <a href="Work">Our Work</a>
          <a href="javascript:void(0);" class="icon" onClick={this.navFunction}>&#9776;</a>
          <a href='https://twitter.com/BananazTech' target='_blank' className="right-align">
            <img className="nav-logo" src={require('../images/twitter-yellow.png')}></img>
          </a>
      </div>
        /* <div className="nav-container">
          <div className='nav-left'>
            <Link to="/" className="nav-link">
              <img className="nav-logo" src={require('../images/navLogo.png')}></img>
            </Link>

          </div>
          <div className='link-container'>
            <div className='nav-left'>
              <ul className='nav-list'>
                <li className="nav-item">
                  <Link className="nav-link" to="/Team">Team</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/Bots">Bots</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/Pricing">Pricing</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/Work">Our Work</Link>
                </li>
              </ul>
            </div>
          
            <div className="nav-right">
              <a href='https://twitter.com/BananazTech' target='_blank' className="nav-link">
                <img className="nav-logo" src={require('../images/twitter-yellow.png')}></img>
              </a>
            </div>
          </div>
        </div> */
    );
  }
}