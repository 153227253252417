import React from 'react';

export default class Team extends React.Component {
  render() {    
    return (
        <body>
            <section className='team-section'>
              <div class="wrapper">
                <div className='page-header'>
                    <h1 className='underline-heading'>Meet The Team</h1>
                </div>
                <div className='top-left-img'>
                    <img className='team-left-img' src={require('../../images/47.png')}></img>
                </div>
                <div className='top-left-text'>
                    <span className='vertical-align'>
                        <h2>Wock</h2>
                        <p>Founder of Bananaz Technology. Frontend development and business development.</p>
                        <a href='https://twitter.com/WockNFT' target='_blank' className="team-link">
                            <img className="team-logo" src={require('../../images/twitter-blue.png')}></img>
                            WockNFT
                        </a>
                    </span>  
                </div>

                <div className='top-right-img'>
                    <img className='team-left-img' src={require('../../images/2091.png')}></img>
                </div>
                <div className='top-right-text'>
                    <span className='vertical-align'>
                        <h2>Cantfixstupid</h2>
                        <p>Founder of Bananaz Technology. Devops and  full stack engineering</p>
                        <a href='https://twitter.com/AaronsNFT' target='_blank' className="team-link">
                            <img className="team-logo" src={require('../../images/twitter-blue.png')}></img>
                            AaronsNFT
                        </a>
                    </span>
                </div>

                <div className='bot-left-img'>
                    <img className='team-left-img' src={require('../../images/geisha.png')}></img>
                </div>
                <div className='bot-left-text'>
                    <span className='vertical-align'>
                        <h2>Zach</h2>
                        <p>Business acquisition and public relations.</p>
                        <a href='https://twitter.com/etherzach' target='_blank' className="team-link">
                            <img className="team-logo" src={require('../../images/twitter-blue.png')}></img>
                            etherzach
                        </a>
                    </span>
                </div>

                <div className='bot-right-img'>
                    <img className='team-left-img' src={require('../../images/geisha2.png')}></img>
                </div>
                <div className='bot-right-text'>
                    <span className='vertical-align'>
                        <h2>Jersey McGriddle</h2>
                        <p>Devops and full stack engineering.</p>
                        <a href='https://twitter.com/J_Griddle' target='_blank' className="team-link">
                            <img className="team-logo" src={require('../../images/twitter-blue.png')}></img>
                            J_Griddle
                        </a>
                    </span>
                </div>

              </div>
              
            </section>
        </body>
      
    );
  }
}