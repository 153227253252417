import React from 'react';

export default class Pricing extends React.Component {
  render() {    
    return (
        <body>
            <section className='bots-section'>
              <div class="bots-wrapper">
                <div className='page-header'>
                    <h1 className='underline-heading'>Pricing</h1>
                </div>
                <div className='pro package'>
                    <h1>Pro</h1>
                    <p>Discord Essentials that every community needs</p>
                    <h2 class='underline-heading'>.1Ξ/mo</h2>
                    <h2 className='features-header'>Plan Features:</h2>
                    <ul className='features-list'>
                        <li>Discord Sales & Listings</li>
                        <li>24/7 Hosting</li>
                        <li>Floor Status</li>
                        <li>Refresh cycle: 5 min</li>
                    </ul>
                </div>

                <div className='premium package'>
                    <h1>Premium</h1>
                    <p>Core package for taking your community to the next level</p>
                    <h2 class='underline-heading'>.2Ξ/mo</h2>
                    <h2 className='features-header'>Plan Features:</h2>
                    <ul className='features-list'>
                        <li>Discord Sales & Listings</li>
                        <li>Twitter Feed</li>
                        <li>24/7 Hosting</li>
                        <li>Floor Status</li>
                        <li>Holder Count</li>
                        <li>Refresh cycle: 2.5 min</li>
                    </ul>
                </div>

                <div className='bananaz package'>
                    <h1>Bananaz</h1>
                    <p>High performance package for a community goin’ bananaz</p>
                    <h2 class='underline-heading'>.3Ξ/mo</h2>
                    <h2 className='features-header'>Plan Features:</h2>
                    <ul className='features-list'>
                        <li>Discord Sales & Listings</li>
                        <li>Twitter Feed</li>
                        <li>24/7 Hosting</li>
                        <li>Floor Status</li>
                        <li>Holder Count</li>
                        <li>Rarity.Tools Integration free</li>
                        <li>Mint/Burn Channel</li>
                        <li>Priority Service</li>
                        <li>Refresh cycle: 1 min</li>
                    </ul>
                </div>
                <div className='extra-bots'>
                    <h1>Other Bots</h1>
                    <ul className='features-list'>
                        <li>Survival Games</li>
                        <li>Bananaz Watchdog Security</li>
                        <li>Zora Auctions</li>
                        <li>Role-Based Notifications</li>
                    </ul>
                </div>

                <div className='custom-bots'>
                    <h1>Custom Bots</h1>
                    <p>We are open for specific, commissioned work! Let us know what you're looking for and we'll give you a quote!</p>
                </div>

                <div className='specifics'>
                    <h1>Can't find a package that suits your needs?</h1>
                    <p>Get in touch with us!
                      <br></br>
                      <br></br>
                      Email: Contact@Bananaz.Tech
                      <br></br>
                      Discord: Wock#8449
                    </p>
                </div>

              </div>
              
            </section>
        </body>
      
    );
  }
}