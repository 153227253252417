import React from 'react';

export default class LandingPage extends React.Component {
  render() {    
    return (
        <body>
            <section>
              <img className="landing-logo" src={require('../../images/BananazTechSkinny.png')}></img>
            </section>
        </body>
      
    );
  }
}