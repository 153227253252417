import React from 'react';

export default class Bots extends React.Component {
  render() {    
    return (
        <body>
            <section className='bots-section'>
              <div class="bots-wrapper">
                <div className='page-header'>
                    <h1 className='underline-heading'>Bananaz Bots</h1>
                </div>
                <div className='bots-info'>
                    <div class='bots-info-row'>
                        <h2>Discord Webhooks</h2>
                        <p>Market activity piped right into the community's heart</p>
                    </div>
                    <div class='bots-info-row'>
                        <h2>Tweets</h2>
                        <p>Sales heating up? Press that retweet button and share it with the world</p>
                    </div>
                    <div class='bots-info-row'>
                        <h2>Rarity.Tools</h2>
                        <p>Save everybody some time and have the Rarity.Tools rankings readily available and integrated into your webhooks</p>
                    </div>
                    <div class='bots-info-row'>
                        <h2>Detailed Information</h2>
                        <p>Information and links every step of the way (We also monitors LooksRare!)</p>
                    </div>
                </div>
              </div>
              
            </section>
        </body>
      
    );
  }
}