import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './App.css';


import Nav from './Components/Nav'
import Footer from './Components/Footer';
import LandingPage from './Components/Pages/LandingPage';
import Team from './Components/Pages/Team';
import Bots from './Components/Pages/Bots';
import Pricing from './Components/Pages/Pricing';
import Work from './Components/Pages/Work';


function App() {
  return (
    <Router>
      <Nav />
        <Routes>
          <Route exact path="/" element={<LandingPage />}/>
          <Route exact path="/Team" element={<Team />}/>
          <Route exact path="/Bots" element={<Bots/>}/>
          <Route exact path="/Pricing" element={<Pricing/>}/>
          <Route exact path="/Work" element={<Work/>}/>
          <Route path="*" element={<LandingPage/>}/>
        </Routes>
      <Footer />
    </Router>
  );
}

export default App;
